import { NgModule } from '@angular/core';
import { AtsPlatformModule, LayoutComponent, provideMenuItems } from 'ats-platform';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SmartToolModule } from 'ats-smart-tool';
import { MENU } from './app.menu';

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      onSameUrlNavigation: 'reload',
    }),
    AtsPlatformModule.forRoot(),
    SmartToolModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      progressBar: true,
      maxOpened: 5,
    })
  ],
  providers: [
    provideMenuItems(MENU)
  ],
  declarations: [
  ],
  entryComponents: [
  ],
  bootstrap: [LayoutComponent]
})
export class AppModule { }
